<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading">
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
                          @click="createNewItem">
                    <feather-icon size="17" icon="HelpCircleIcon"/>
                    سوال جدید
                </b-button>
            </div>

            <b-modal
                    ref="msg-modal"
                    cancel-variant="outline-secondary"
                    ok-title="ذخیره"
                    cancel-title="لغو"
                    centered
                    title="پیام جدید"
                    @ok.prevent="submit"
                    v-model="modal"
                    size="lg"
            >
                <b-overlay :show="submitting">
                    <b-form>
                        <b-form-group label="عنوان سوال">
                            <b-form-input
                                    type="text"
                                    placeholder="عنوان"
                                    v-model="newItem.question"
                            />
                        </b-form-group>
                        <b-form-group label="متن جواب">
                            <b-form-textarea placeholder="متن جواب" v-model="newItem.answer"/>
                        </b-form-group>
                         <b-form-group label="انتخاب زبان">
                            <b-form-select  v-model="newItem.lang" :options="langOptions"></b-form-select>
                        </b-form-group>
                    </b-form>
                </b-overlay>
            </b-modal>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="false"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        :sort-desc.sync="isSortDirDesc"
                        
                >

                    <template #cell(answer)="{item,index}">
                        <p class="ellipsis cursor-pointer mb-0" :show="show[index]"
                           @click.prevent="fullShow(index)">
                            {{item.answer}}</p>
                    </template>

                    <template #cell(action)="{item,index}">
                        <template
                                v-if="$havePermission('UPDATE_FAQ') ||
                                 $havePermission('DELETE_FAQ')"
                        >
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item v-if="$havePermission('UPDATE_FAQ')" @click="getItem(index)">
                                    <feather-icon icon="HelpCircleIcon"/>
                                    <span class="align-middle ml-50">مشاهده و تغییر</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('DELETE_FAQ')" @click="removeItem(item.id)">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">حذف راهنما</span>
                                </b-dropdown-item>

                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BCard,
        BButton,
        BTable,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BModal,
        BOverlay,
        BDropdown,
        BDropdownItem,
        BFormSelect
        // BBadge,
        // BAvatar,
    } from 'bootstrap-vue'
    import NotAllowed from "@/layouts/components/NotAllowed";

    export default {
        name: 'Messages',
        components: {
            NotAllowed,
            BCard,
            BButton,
            BTable,
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BModal,
            BOverlay,
            BDropdown,
            BDropdownItem,
            BFormSelect
            // BBadge,
            // BAvatar,
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 200,
            sortBy: '',
            isSortDirDesc: '',
            modal: false,
            userData: [],
            show: [],
            isActive: false,
            submitting: false,
            perPageOptions: [5, 10, 20, 50, 100],
             langOptions:[
                { value: 'fa', text: 'فارسی' },
                { value: 'en', text: 'Enlish' },
            ],
            newItem: {
                "answer": "",
                "id": 0,
                "question": "",
                "lang":""
            },
            items: [
                {
                    "answer": "",
                    "id": 0,
                    "question": ""
                },
            ],
            columns: [
                {
                    label: 'سوال',
                    key: 'question',
                    sortable: true,
                    searchType: 'text'
                },
                // {
                //     label: 'تاریخ ایجاد سوال',
                //     key: 'createdAtDateTime',
                //     sortable: true,
                //     searchType: 'date'
                // },
                {
                    label: 'متن جواب',
                    key: 'answer',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'عملیات',
                    key: 'action',
                },
            ],
        }),
        methods: {
            fullShow(e) {
                this.show[e] = this.show[e] ? false : true
                for (let a = 0; a < this.show.length; a++) {
                    if (a !== e)
                        this.show[a] = false
                }
                this.$forceUpdate()
            },
            createNewItem() {
                this.newItem = {
                    "answer": "",
                    "id": 0,
                    "question": ""
                }
                this.$refs['msg-modal'].show()
            },
            async submit() {
                if(!this.newItem.id){
                    if (!this.submitting) {
                        this.submitting = true
                        await this.$axios.post('/faq', this.newItem)
                        this.$refs['msg-modal'].hide();
                        this.submitting = false
                        this.getData()
                    }
                }else{
                    if (!this.submitting) {
                        this.submitting = true
                        await this.$axios.post('/faq/'+this.newItem.id,this.newItem)
                        this.$refs['msg-modal'].hide();
                        this.submitting = false
                        this.getData()
                    }
                }
            },
            async getItem(e) {
                this.newItem = this.items[e]
                this.$refs['msg-modal'].show()
            },
            async removeItem(e) {
                this.state.loading = true
                await this.$axios.delete('/faq/' + e)
                this.getData()
            },
            async getData() {
                this.state.loading = true
                const res = await this.$axios.get('/faq')
                const resEn = await this.$axios.get('/faq?lang=en')
                this.items = [...res.data,...resEn.data]
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }

    .ellipsis {
        overflow: hidden;
        width: calc(100vw - 750px);
        text-overflow: ellipsis;
        white-space: nowrap;

        &[show] {
            white-space: normal;
        }

        @media(max-width: 1200px) {
            width: calc(100vw - 470px);
            min-width: 200px;
        }
    }
</style>
